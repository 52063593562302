const ProductTypes = {
    WAT_FOCUS:'WAT_FOCUS',
    WAT_FOCUS_INDEPENDENT:'WAT_FOCUS_INDEPENDENT',
    WAT_UX:'WAT_UX',
    WAT_UX_QUANTI:'WAT_UX_QUANTI',
    WAT_CORPORATE:'WAT_CORPORATE',
    WAT_LAB:'WAT_LAB',
    WAT_SURVEY:'WAT_SURVEY',
    EXPERIENCE_LAB: 'EXPERIENCE_LAB',
    CLICK_TEST: 'CLICK_TEST',
    WAT_NEURO: 'WAT_NEURO'
}

const PRODUCTS_ENABLED_RELATION: Record<string, (isPublic: boolean) => string> = {
    WAT_FOCUS: (isPublic) => isPublic ? 'WAT_FOCUS_NOPANEL' : 'WAT_FOCUS_PANEL',
    WAT_FOCUS_INDEPENDENT: (isPublic) => isPublic ? 'WAT_FOCUS_NOPANEL' : 'WAT_FOCUS_PANEL',
    WAT_UX: (isPublic) => isPublic ? 'WAT_UX_THINKING_ALOUD_NOPANEL' : 'WAT_UX_THINKING_ALOUD_PANEL',
    WAT_UX_QUANTI: (isPublic) => isPublic ? 'WAT_UX_QUANTI_NOPANEL' : 'WAT_UX_QUANTI_PANEL',
    WAT_CORPORATE: (isPublic) => isPublic ? 'WAT_CORPORATE' : 'WAT_CORPORATE',
    WAT_LAB: (isPublic) => isPublic ? 'WAT_LAB' : 'WAT_LAB',
    WAT_SURVEY: (isPublic) => isPublic ? 'WAT_SURVEY_NOPANEL' : 'WAT_SURVEY_PANEL',
    EXPERIENCE_LAB: (isPublic) => isPublic ? 'WAT_LAB' : 'WAT_LAB',
    CLICK_TEST: (isPublic) => isPublic ? 'WAT_UX_CLICK_TEST_NOPANEL' : 'WAT_UX_CLICK_TEST_PANEL',
    WAT_NEURO: (isPublic) => isPublic ? 'WAT_NEURO' : 'WAT_NEURO'
}

const taskTypes = {
    NEURO_VIDEO:'NEURO_VIDEO'
}

const FocusSubProductTypes = {
    GROUP : 'GROUP',
    INTERVIEW : 'INTERVIEW'
}

const SuiteRoles = {
    SUPERADMIN : 'SUPERADMIN',
    ADMIN: 'ADMIN',
    OBSERVER: 'OBSERVER'
}

const PlanTypes = {
    INTERNAL : 'INTERNAL',
    AD_HOC : 'AD_HOC',
    CREDITS : 'CREDITS',
    MULTI_COMMUNITY : 'MULTI_COMMUNITY',
}

const userOrigin = {
    WAT : 'WAT',
    CLIENT : 'CLIENT'
}

const deviceTypes = {
    ALL_PLATFORMS : 'ALL_PLATFORMS',
    ONLY_MOBILE : 'ONLY_MOBILE',
    ONLY_DESKTOP : 'ONLY_DESKTOP'
}

const neuroObjetives = {
    SALES : 'SALES',
    BRAND : 'BRAND'
}

const SELECTED_PLAN_KEY = 'selectedPlan'

export { ProductTypes, SuiteRoles, PlanTypes, userOrigin, FocusSubProductTypes, taskTypes, deviceTypes, neuroObjetives, PRODUCTS_ENABLED_RELATION, SELECTED_PLAN_KEY }